<template>
   <!-- 站点分析 -->
   <div 
     class="storage"
     v-loading.fullscreen.lock="loading"
     element-loading-text="请求数据中"
     element-loading-background="rgba(255, 255, 255, 0.5)"
    > 
     <div class="append">
       <div class="seach">
         <el-select placeholder="请选择店铺" v-model="pageInfo.station" multiple>
           <el-option
            v-for="item in sationList"
            :key="item.id"
            :label="item.marketplaceName"
            :value="item.id"
            >
            {{item.marketplaceName}}--({{item.shopName}})
           </el-option>
         </el-select>
         <el-select v-model="pageInfo.currency" placeholder="基准货币" clearable @change="tableSearch">
           <el-option
            v-for="item in currencyList"
            :key="item.id"
            :label="item.dicItemValue"
            :value="item.id"
            >
           </el-option>
         </el-select>
         <TimeQuantum 
           style="margin-right:10px" 
           @selectFinish='topTime'
           section="近7天"
           @sectionFinish='sectionSure'
         ></TimeQuantum>
         <el-button type="primary" icon="el-icon-search" @click="tableSearch">查询</el-button>

         <tableDialog :tableList="tableData" @setTableHead="setTableHead" style="margin-right:20px;float:right"></tableDialog>
         <!-- 列表区域 -->
         <el-table
           border
           :data="tableList"
           style="width: 100%"
           :header-cell-style="{background:'#EFEFEF', textAlign:'center', height: '50px'}"
          >
           <template v-for="item in tableHead">
             <el-table-column
               :key="item.prop"
               :prop="item.prop"
               :label="item.name"
               header-align="center"
               :fixed="item.fixed"
               align="center"
               :show-overflow-tooltip="true"
               :width="item.width"
             >
                <template slot="header">
                  <span v-if="item.name =='销售额(¥)'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="bottom">
                      <div slot="content">
                        <span>所指的是：<span style="color: #FFA500">商品的销售额（欧洲市场shipped状态订单已扣VAT）,而非订单金额,</span></span><br/>
                        <span>即不包含各项税费, 运费、礼品费和扣除促销等相关费用的金额。</span><br/>
                        <span>计算逻辑：销售订单<span style="color: #FFA500">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品单价 * 销售数量的金额总和。</span><br/>
                        <span>某一笔订单的销售额（商品销售额），就是这笔订单在亚马逊后台交易详情中的商品价格。</span><br/>
                        <span>① 销售订单的归属日期，以下单时间进行统计;</span><br/>
                        <span>② 如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                        <span>③ 部分pending订单, 亚马逊一段时间内,返回的销售数据可能为0, </span><br/>
                        <span>此时系统会按最近成交的情况进行预算<span style="color: #FFA500">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name =='订单金额(¥)'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>买家实际支付金额的总和</span><br/>
                        <span style="color: #FFA500">（包括销售额、包装费、税费、运费等费用, 但扣除促销金额）。</span><br/>
                        <span>特别说明：部分暂时无法获取支付金额的pending订单, </span><br/>
                        <span>此时系统会按最近成交的情况进行预算, 预算的值=销售额（商品价格*购买数据）。</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name =='FBA费用'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="亚马逊收取的FBA配送费用的总和。">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name =='订单量'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>销售订单<span style="color: #FFA500">（不包括：取消、预售未付款授权 状态的订单）</span>的数量。</span><br/>
                        <span>1、销售订单的归属日期, 以下单时间进行统计;</span><br/>
                        <span>2、如果订单发生退款, 此订单会正常统计订单量;</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='销量'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>销售订单<span style="color: #FFA500">（不包括：取消、预售未付款授权 状态的订单）</span>中的商品数量的总和。</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name == '总支出'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="bottom">
                      <div slot="content">
                        <span>所有支出的总和，支出项包括：广告花费、FBA费用、亚马逊佣金、促销费用、退款金额 + 退款费用、</span><br/>
                        <span>亚马逊其它费用、系统设置商品成本 、系统设置其它成本 。</span><br/>
                        <span><span style="color: #FFA500">特别说明</span>：1、如果亚马逊平台代收了增值税，</span><br/>
                        <span>则亚马逊其它费用中会包含增值税，即总支出中包括增值税; </span><br/>
                        <span>2、coupon类促销订单，系统的促销费用中会包括亚马逊收取的费用, 即总支出中包括coupon的使用费用, </span><br/>
                        <span>因此系统订单中展示的促销费用可能大于亚马逊交易一览中的促销金额；</span><br/>
                        <span>1、如果订单发生退款, 此订单会正常统计销售额, 对应的退款金额会以退款时间, 统计到当日总支出的退款项中;</span><br/>
                        <span>2、部分pending订单，亚马逊短时间内，返回的销售数据可能为0,</span><br/>
                        <span>此时系统会按最近成交的情况进行预算<span style="color: #FFA500">（预算数据项包括：销售额、亚马逊佣金、FBA费用）</span>;</span><br/>
                        <span>3、亚马逊其它费用：亚马逊订单明细中, 除了 商品价格、FBA费用、佣金、促销费用 <span style="color: #FFA500">之外的其它费用总和</span>, </span><br/>
                        <span>此值出现负数的原因是：a、有些促销活动是亚马逊平台搞的，但是这部分费用会被一并统计到总支出的促销费用中, </span><br/>
                        <span>因此需要进行对冲。b、增值税亚马逊未代收, 此时增值税需要加入到收入中, 对于费用来说是负数；c、FBM订单,</span><br/>
                        <span>运费需要加入到收入中，对于费用来说是负数；</span><br/>
                        <span>4、系统设置其它成本：店铺实际运营过程中，产生的一些特殊费用，可以是收入,</span><br/>
                        <span>也可以是支出，用于准确计算利润，可在“库存管理 - 其它成本”中进行上传;</span><br/>
                        <span>5、系统设置商品成本：包括商品的采购成本、物流成本（头程 / FBM）、其它费用,</span><br/>
                        <span>可在“库存管理 - 商品成本”中进行设置或者上传；;</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name == '广告花费(¥)'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>广告被点击，所产生的总费用，相当于您的<span style="color:#FFA500">广告总成本</span> 。</span><br/>
                        <span>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致, </span><br/>
                        <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</span><br/>
                        <span>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口, 不同接口的请求时间或者报告生成时间可能不一致,</span><br/>
                        <span>因此可能导致两个维度下统计结果存在略微不一致;</span><br/>
                        <span>3、计算规则：广告花费 = CPC竞价 * 被点击次数;</span><br/>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name =='亚马逊佣金'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="亚马逊收取的平台费用的总和。">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name == '促销费'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>促销所产生的相关费用, 例如, 折扣对应的费用等都计入成本。</span><br/>
                        <span><span style="color: #FFA500">特别说明</span>：coupon类促销订单, 系统的促销费用中会包括亚马逊收取的费用,</span><br/>
                       <span>即总支出中包括coupon的使用费用, 因此系统订单中展示的促销费用可能大于亚马逊交易一览中的促销金额;</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name =='退货数'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="退款订单中的商品数量的总和">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name == '退货率'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="计算规则：退货率 = 退货量 / 销售量 * 100%">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='退款金额'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="退款时, 给客户退款金额的总和。">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name == '退款费用'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="退款时, 亚马逊收取的费用和返还费用的总和。  ">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='亚马逊其他费用'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="bottom">
                      <div slot="content">
                        <span>亚马逊订单明细中，除了 商品价格、FBA费用、佣金、促销费用 <span style="color: #FFA500">之外的其它费用总和</span>。</span><br/>
                        <span><span style="color: #FFA500">特别说明</span>：1、如果亚马逊平台代收了增值税，则亚马逊其它费用中会包含增值税。</span><br/>
                        <span>2、此值出现负数的原因是：</span><br/>
                        <span>a、有些促销活动是亚马逊平台搞的，但是这部分费用会被一并统计到总支出的促销费用中，因此需要进行对冲。</span><br/>
                        <span>b、增值税亚马逊未代收，此时增值税需要加入到收入中，对于费用来说是负数。</span><br/>
                        <span>c、FBM订单，运费需要加入到收入中，对于费用来说是负数；</span>      
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name == '商品成本'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>包括商品的采购成本、物流成本（头程 / FBM）、其它费用,</span><br/>
                        <span>可在“库存管理 - 商品成本”中进行设置或者上传。</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='其他成本'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>店铺实际运营过程中，产生的一些特殊费用，可以是收入，也可以是支出,</span><br/>
                        <span>用于准确计算利润，可在“库存管理 - 其它成本”中进行上传。</span><br/>
                        <span><span style="color: #FFA500">特别说明</span>：系统设置其它成本，只会被统计到店铺维度的总支出中，并参与利润计算。</span><br/>
                        <span><span style="color: #FFA500">但不会被均分到商品维度</span>的总支出中，参加商品利润的计算。</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='毛利润(¥)'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="计算规则：毛利润 = 销售额 - 总支出">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='毛利率'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="计算规则：毛利率 = 毛利润 / 销售额 * 100%">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='广告销售额'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span> 通过广告带来的销售额。</span><br/>
                        <span>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致, </span><br/>
                        <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致；</span><br/>
                        <span>2、以商品和站点不同维度查看数据，因数据来自亚马逊不同的接口, 不同接口的请求时间或者报告生成时间可能不一致,</span><br/>
                        <span>因此可能导致两个维度下统计结果存在略微不一致;</span><br/>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name =='广告曝光量'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>广告投放给买家的展示总数。</span><br/>
                        <span>没有曝光量就无法带来点击及购买转化，因此整体预算内，曝光量越高越好。</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='广告点击量'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top" content="广告被点击的次数">
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='广告订单量'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>通过广告带来的订单量。</span><br/>
                        <span>1、因近30天（尤其近3天）亚马逊接口返回的数据，可能与亚马逊控制台展现的数据存在略微不一致, </span><br/>
                        <span>因此可能导致系统统计结果与亚马逊控制台展现的数据存在略微不一致; </span><br/>
                        <span>2、以商品和站点不同维度查看数据, 因数据来自亚马逊不同的接口, 不同接口的请求时间或者报告生成时间可能不一致,</span><br/>
                        <span>因此可能导致两个维度下统计结果存在略微不一致;</span><br/>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>  
                  </span>
                  <span v-else-if="item.name=='广告订单占比'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>通过广告带来的订单量占总订单量的比例。</span><br/>
                        <span>计算规则：广告订单量占比 = 广告订单量 / 订单量 * 100%</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='广告点击率'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>每一次曝光被点击的概率。</span><br/>
                        <span>计算规则：广告点击率 = 点击量 / 曝光量 * 100%</span><br/>
                        <span>亚马逊广告点击率一般在0.6%-2.5%左右。</span><br/>
                        <span>点击率高：说明选词匹配度越高，同时您的商品标题、主图、价格、评论数量，较好的吸引用户点击。</span><br/>
                        <span>点击率低：建议对选词，商品标题、主图、价格、评论数量进行优化。</span><br/>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='CVR'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>每一次点击带来订单的概率。</span><br/>
                        <span>计算规则：CVR = 订单数 / 点击量 * 100%;</span><br/>
                        <span>例如：CVR为10%，每100次点击，能带来10笔广告订单。</span><br/>
                        <span>亚马逊广告，CVR一般为5-10%左右</span><br/>
                        <span>CVR的重要影响因素：商品图片、卖点、评论内容、促销活动</span><br/>
                        <span>如果CVR低，建议您优化listing，根据商品的受众人群</span><br/>
                        <span>提炼卖点、设计精美的场景图、善用Q&A，Review等。</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name == '访问次数'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>用户访问您的 Amazon.com 页面的次数。24 小时内的所有活动都将视为一次会话。</span><br/>
                        <span>数据来自于亚马逊后台业务报告，可在 “经营分析” - “业务报告” 中进行上传。</span><br/>
                        <span><span style="color: #FFA500">特别说明</span>：按SKU维度查看数据时，如果1个ASIN下有多个SKU，导入的ASIN业务报告数据, </span><br/>
                        <span>只会随机填充到一个SKU，这会导致其它的SKU没有业务报告导入的相关数据。</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                  <span v-else-if="item.name=='访问转化率'">
                    <span>{{item.name}}</span>
                    <el-tooltip effect="dark" placement="top">
                      <div slot="content">
                        <span>计算规则：访问转化率 = 销量 / 访问次数 * 100%</span><br/>
                        <span>访问次数，数据来自于亚马逊后台业务报告，可在 “经营分析” - “业务报告” 中进行上传。</span><br/>
                        <span><span style="color: #FFA500">特别说明</span>：按SKU维度查看数据时，如果1个ASIN下有多个SKU，导入的ASIN业务报告数据, </span><br/>
                        <span>只会随机填充到一个SKU，这会导致其它的SKU没有业务报告导入的相关数据。</span>
                      </div>
                      <span class="el-icon-question"></span>
                    </el-tooltip>
                  </span>
                </template>
             </el-table-column>
           </template>
         </el-table>
       </div>
     </div>
   </div>
</template>

<script>
import tableDialog from '@/components/tableDialog.vue'
var moment = require("moment");
import TimeQuantum from '@/components/TimeQuantum'
import {
  getPageInit,   // 查询初始化
  handleSearch   // 查询事件
}from "@/api/stationAnalysis.js"
export default {
    components:{
      TimeQuantum,
      tableDialog
    },
    data() {
      return {
        loading: false,
        pageInfo: {
          station: [],
          currency: "",  // 基准货币
          dateTypeName: "",  // 日期范围
          startDate: "",
          endDate: "",
        },
        sationList: [],   // 站点列表
        currencyList: [],  // 货币集合
        tableList: [],  // 列表数据
        tableHead: [],
        tableData: [
          {
           name: '站点',
           prop: 'marketplaceName',
           fixed: 'left'
          },
          {
            name: '店铺',
            prop: 'shopName',
            fixed: 'left'
          },
          {
            name: '时间段',
            prop: 'date',
            fixed: 'left'
          },
          {
            name: '销售额(¥)',
            prop: 'shopSalePrice'
          },
          {
            name: '订单金额(¥)',
            prop: 'shopOrderPrice'
          },
          {
            name: 'FBA费用',
            prop: 'fbaCost'
          },
          {
            name: '订单量',
            prop: 'orderCounts'
          },
          {
            name: '销量',
            prop: 'saleCounts'
          },
          {
            name: '总支出',
            prop: 'totalCost'
          },
          {
            name: '广告花费(¥)',
            prop: 'advertiseCost'
          },
          {
            name: '亚马逊佣金',
            prop: 'shopReferralPrice'
          },
          {
            name: '促销费',
            prop: 'promotionCost'
          },
          {
            name: '退货数',
            prop: 'backOrderCounts'
          },
          {
            name: '退货率',
            prop: 'backOrderRate'
          },
          {
            name: '退款金额',
            prop: 'backOrderAmount'
          },
          {
            name: '退款费用',
            prop: 'backOrderCost'
          },
          {
            name: '亚马逊其他费用',
            prop: 'amzOtherCost'
          },
          {
            name: '商品成本',
            prop: 'productCost'
          },
          {
            name: '其他成本',
            prop: 'otherCost'
          },
          {
            name: '毛利润(¥)',
            prop: 'grossProfit'
          },
          {
            name: '毛利率',
            prop: 'grossProfitRate'
          },
          {
            name: '广告销售额',
            prop: 'adSalePrice'
          },
          {
            name: '广告曝光量',
            prop: 'adExposureCounts'
          },
          {
            name: '广告点击量',
            prop: 'adClickCounts'
          },
          {
            name: '广告订单量',
            prop: 'adOrderCounts'
          },
          {
            name: '广告订单占比',
            prop: 'adOrderRate'
          },
          {
            name: '广告点击率',
            prop: 'adClickRate'
          },
          {
            name: 'CVR',
            prop: 'cvr'
          },
          {
            name: '访问次数',
            prop: 'visitCounts'
          },
          {
            name: '访问转化率',
            prop: 'visitRate'
          }
        ]
      }  
    },
    created() {
      this.tableHead = this.tableData;
      this.getPageInitData();
    },
    mounted() {
      this.tableSearch();
    },
    methods: {
      setTableHead(list) {
        this.tableHead = list;
      },
      getPageInitData() {
        getPageInit()
        .then(res=> {
          let result = res.data.data;
          this.sationList = [];
          this.currencyList = [];
          if(res.data.code == 200) {
            this.sationList = result.shopList;
            this.currencyList = result.currencyList;
          }
        })
      },
      // 查询事件
      tableSearch() {
        this.loading = true;
        let data = {
          relationIds: this.pageInfo.station,
          startDate: this.pageInfo.startDate,
          endDate: this.pageInfo.endDate,
          currencyType: this.pageInfo.currency,
          dateTypeName: this.pageInfo.dateTypeName
        }
         handleSearch(data)
          .then(res=> {
            this.tableList = [];
            if(res.data.code == 200) {
              this.loading = false;
              this.tableList = res.data.data;
            }else if(res.data.code == 500) {
              this.$message.error(res.data.message);
            }
          })
      },
      topTime(val) {
        this.pageInfo.startDate = val.value[0];
        this.pageInfo.endDate = val.value[1]; 
        this.pageInfo.dateTypeName = val.dateTypeName    // 选择时间段
      }
    }
}
</script>

<style lang="scss" scoped>
.storage {
  .append {
    height: 50px;
    display: flex;
    justify-content: flex-start;
    .seach {
      width: 100%;
      .el-select {
        width:224px;
        margin-right: 20px;
      }
      .el-table {
        margin-top: 25px;
      }
    }
  }
}
</style>